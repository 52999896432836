import { useEffect, useState } from "react";
import { useFormik } from "formik";
import axios from "axios";
import localforage from "localforage";
import { LoginVld } from "../validations/Email";
import { BusinessService } from "../services";
import { useNavigate } from "react-router-dom";
import { CircularProgress } from "@material-ui/core";

export default function EmailComponent() {
  const [user, setUser] = useState({});
  const [error, setError] = useState("");
  const [load, setLoad] = useState(false);


  const history = useNavigate();

  useEffect(() => {
    gettingData();
  }, []);

  async function gettingData() {
    let data = await localforage.getItem("user");
    setUser(data);

    //console.log(data);
  }

  const handleSubmitFunc = async () => {

    let payload;
    setLoad(true);
    const user = await localforage.getItem('user');

if(user.brands && user.brands.length == 1){
  const selectedBrand = await localforage.getItem('brands')
  const { error, response } = await BusinessService.Phone({ query: { email: formik.values.Email, idKey: user.idKey, brandId: selectedBrand[0]._id } })
  if (response && response.success) {
    payload = {
      brandId: response.data[0].pass.brandId,
      passId: response.data[0].pass._id,
    }
    //console.log('pool', payload);
  }
  else{
    console.log('gerror',error);
    // setError(error.message)
  }
  if (payload) {
    const { response, error } = await BusinessService.GetPublicPass({ query: { _id: payload.passId, delete: false } });
    // setPass(response.data[0])
    if(response){
      //console.log('response 2',response)
      history('/spend', { state: { pass: response.data[0] , payload :payload  } })}
      setLoad(false);
  }
  
}
else{

    const selectedBrand = await localforage.getItem('selectedBrand')
    const { error, response } = await BusinessService.Phone({ query: { email: formik.values.Email, idKey: user.idKey, brandId: selectedBrand._id } })

    if (response && response.success) {
      payload = {
        brandId: response.data[0].pass.brandId,
        passId: response.data[0].pass._id,
      }
      //console.log('pool', payload);
    }
    else{
      console.log('gerror',error);
      // setError(error.message)
    }
    //console.log('pool2', payload);

    if (payload) {
      const { response, error } = await BusinessService.GetPublicPass({ query: { _id: payload.passId, delete: false } });
      // setPass(response.data[0])
      if(response){
        //console.log('response 2',response)
        history('/spend', { state: { pass: response.data[0] , payload :payload  } })}

    }
   
    // const growthtool = await BusinessService.GetQueriedGrowthtool({ query: { _id: response.data[0].pass.growthToolId } })
    // if (growthtool && growthtool.response && growthtool.response.success) payload.flowIds = growthtool.response.data.flowId
    // history('/flow', {state: {payload: payload}})
    // }
  setLoad(false);
  }
  };

  const initialValues = {
    Email: "",
  };

  async function submitFunction(event) {
    event.preventDefault();
  }

  const formik = useFormik({
    initialValues: { ...initialValues },
    onSubmit: submitFunction,
    validationSchema: LoginVld,
    isInitialValid: false,
  });

  //console.log(formik.values);
  const handlKeyDown = (e) => {
    if(e.code === 'Enter'){ handleSubmitFunc()}
  }

  return (
    <div className="label mt-70 plr-res">
      <div className="user-id">
        Customer Email
      </div>
      <input
        className="form-control form-control-lg mt-8"
        type="email"
        name="Email"
        placeholder="Enter customer's email address"
        onChange={formik.handleChange}
        value={formik.values.Email}
        onKeyDown={(e)=>handlKeyDown(e)}

      />
      <div className="d-flex btn-sheild">
      <button
        type="button"
        onClick={handleSubmitFunc}
        className="btn btn-primary mt-20 w-10 ph-responsive"
        disabled={!formik.isValid}
      >
        <b> {load ?
          <CircularProgress size={18} color={"inherit"} />
          : "Submit"}</b>
      </button>
      </div>

      <span className="error">
        {formik.errors.Email
          ? formik.errors.Email
          : error
            ? ""
            : ""}
      </span>
    </div>
  );
}
